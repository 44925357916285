import Vue from "vue";
import Vuex from "vuex";
import { i18n } from "@/i18n/index.js";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    theme: "light",
    language: "ua",
    activeCategories: ["supermarket", "shelter", "playground"],
  },
  mutations: {
    setTheme(state, theme) {
      state.theme = theme;
    },
    setLanguage(state, language) {
      state.language = language;
      i18n.locale = language;
    },
    toggleCategory(state, category) {
      if (state.activeCategories.includes(category)) {
        state.activeCategories = state.activeCategories.filter(
          (c) => c !== category
        );
      } else {
        state.activeCategories.push(category);
      }
    },
  },
  actions: {
    setTheme({ commit }, theme) {
      commit("setTheme", theme);
    },
    setLanguage({ commit }, language) {
      commit("setLanguage", language);
    },
    toggleCategory({ commit }, category) {
      commit("toggleCategory", category);
    },
  },
  getters: {
    getTheme: (state) => state.theme,
    getMapBaseUrl: (state) => {
      if (state.theme === "dark") {
        return "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png";
      }
      return "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png";
    },
    getLanguage: (state) => state.language,
    getActiveCategories: (state) => state.activeCategories,
  },
  modules: {},
  plugins: [createPersistedState()],
});
