/* eslint-disable prettier/prettier */
import en from "./en.json";
import de from "./de.json";
import ru from "./ru.json";
import ua from "./ua.json";
import VueI18n from "vue-i18n";
import Vue from "vue";

export const defaultLocale = "ua";

export const languages = { en: en, de: de, ua: ua, ru: ru };

const messages = Object.assign(languages);

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: defaultLocale,
    fallbackLocale: "en",
    messages,
});
