<template>
  <v-app>
    <v-app-bar app dense>
      <div>
        <v-btn
          v-if="!$vuetify.theme.dark"
          icon
          small
          fab
          @click="theme = 'dark'"
        >
          <v-icon class="mr-1">mdi-moon-waxing-crescent</v-icon>
        </v-btn>

        <v-btn v-else icon small fab @click="theme = 'light'">
          <v-icon color="yellow">mdi-white-balance-sunny</v-icon>
        </v-btn>
      </div>
      <v-spacer />
      <LanguagePicker />
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import LanguagePicker from "@/components/LanguagePicker.vue";
export default {
  name: "App",
  components: {
    LanguagePicker,
  },
  mounted() {
    this.$store.dispatch("setLanguage", this.$store.getters.getLanguage);
    this.$store.dispatch("setTheme", this.$store.getters.getTheme);
  },
  data() {
    return {};
  },
  watch: {
    theme: {
      handler(value) {
        this.$vuetify.theme.dark = value === "dark";
      },
      immediate: true,
    },
  },
  computed: {
    theme: {
      get() {
        return this.$store.getters.getTheme;
      },
      set(value) {
        this.$store.dispatch("setTheme", value);
      },
    },
  },
};
</script>

<style lang="scss">
/* width */
::-webkit-scrollbar {
  width: 0.625em;
}

/* Track */
::-webkit-scrollbar-track {
  background: #2c2c2c;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(109, 109, 109);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(122, 122, 122);
}

html {
  overflow-y: auto;
}
</style>
