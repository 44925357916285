<template>
  <div>
    <MapOverlay @updateMarkers="updateMarkers" style="position: absolute" />
    <l-map
      style="height: 100%; z-index: 0"
      :zoom="zoom"
      :center="center"
      :max-bounds="maxBounds"
      :minZoom="12"
      ref="map"
      :options="{ zoomControl: false }"
    >
      <l-tile-layer :url="baseUrl" :attribution="attribution"></l-tile-layer>
      <l-marker
        v-for="(marker, index) in markers"
        :key="index"
        :lat-lng="marker.latLng"
        @click="markerClick(marker)"
      >
        <l-icon>
          <img v-if="marker.image" :src="getImgUrl(marker.image)" width="30" />
          <v-icon class="text--primary" v-else>{{ marker.icon }}</v-icon>
        </l-icon>
      </l-marker>
      <l-control-zoom position="topright"></l-control-zoom>
    </l-map>
    <v-snackbar v-model="snackbar" timeout="100000" :multi-line="true">
      <div class="d-flex">
        <img
          v-if="snackbarData.image"
          :src="getImgUrl(snackbarData.image)"
          style="width: auto; height: auto; max-width: 60px; max-height: 60px"
        />
        <v-icon x-large v-else>{{ snackbarData.icon }}</v-icon>
        <div class="pl-2">
          <h2>
            {{ snackbarData.name }}
          </h2>
          <p>
            {{ $t(`categories.${snackbarData.category}`) }}
          </p>
          <!-- <a
            :href="`geo:${snackbarData.latLng[0]},${snackbarData.latLng[1]}?q=${snackbarData.latLng[0]},${snackbarData.latLng[1]}(${snackbarData.name})`"
          >
            {{ $t("snackbar.startNavigation") }}
          </a> -->
          <a
            :href="`https://www.google.com/maps/search/?api=1&query=${snackbarData.latLng[0]},${snackbarData.latLng[1]}`"
            target="_blank"
          >
            {{ $t("snackbar.startNavigation") }}
          </a>
        </div>
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { latLngBounds } from "leaflet";
import "leaflet.locatecontrol";
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LControlZoom, LIcon } from "vue2-leaflet";
import MapOverlay from "@/components/MapOverlay.vue";

export default {
  components: {
    LMap,
    LTileLayer,
    LControlZoom,
    LMarker,
    LIcon,
    MapOverlay,
  },
  data() {
    return {
      //   url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      //   url: "https://a.tile.openstreetmap.de/{z}/{x}/{y}.png",
      //   url: "https://stamen-tiles.a.ssl.fastly.net/toner/{z}/{x}/{y}.png",
      url: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 15,
      center: [49.665883171159585, 10.461019728787285],
      maxBounds: latLngBounds([
        [49.71236419194937, 10.332609029092234],
        [49.60644541253657, 10.554739761442551],
      ]),
      markers: [],
      snackbar: false,
      snackbarData: { name: "", image: "", latLng: [], category: "" },
    };
  },
  mounted() {
    L.control
      .locate({
        position: "topright",
        strings: {
          title: "Show location",
        },
        flyTo: true,
      })
      .addTo(this.$refs.map.mapObject);
  },
  computed: {
    baseUrl() {
      return this.$store.getters["getMapBaseUrl"];
    },
  },
  methods: {
    updateMarkers(markers) {
      this.markers = markers;
      if (this.markers.length > 0) {
        this.$refs.map.mapObject.fitBounds(
          this.markers.map((m) => {
            return m.latLng;
          }),
          {
            padding: [30, 30],
          }
        );
      }
    },
    markerClick(marker) {
      this.snackbarData = marker;
      this.snackbar = true;
    },
    getImgUrl(imageName) {
      if (!imageName) {
        return "";
      }
      const images = require.context("../assets/", false, /\.png$/);
      return images(`./${imageName}.png`);
    },
  },
};
</script>

<style lang="scss">
.leaflet-control-locate-location-arrow::before {
  content: "⊗";
  font-size: 1.5rem;
  color: #222;
}

.leaflet-control-zoom {
  .leaflet-control-zoom-in {
    color: #222;
  }
  .leaflet-control-zoom-out {
    color: #222;
  }
}
</style>
