<template>
  <div class="home">
    <Map style="height: calc(100vh - 48px)" />
  </div>
</template>

<script>
// @ is an alias to /src
import "leaflet/dist/leaflet.css";
import Map from "@/components/Map.vue";

export default {
  name: "Home",
  components: { Map },
};
</script>
