<template>
  <v-menu open-on-hover offset-y>
    <template v-slot:activator="{ on: menu }">
      <v-btn class="text--secondary px-0 px-md-2" text v-on="{ ...menu }">
        <v-img
          :src="currentImage"
          alt="Switch Language"
          contain
          tile
          height="34"
        ></v-img>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item>
        <v-list-item-title class="text-center font-weight-bold text-uppercase">
          {{ $t("languagePicker.pickLanguage") }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-for="locale in locales"
        :key="locale.locale"
        :class="getClass(locale)"
        @click="selectLocale(locale.locale)"
      >
        <v-img
          :src="locale.source"
          contain
          tile
          width="24"
          height="18"
          class="ma-2 pa-2"
        />
        <v-list-item-title>{{ locale.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import locales from "@/i18n/locales.js";

export default {
  name: "LanguagePicker",
  data() {
    return {
      locales,
    };
  },
  computed: {
    currentLocale() {
      return locales.find((f) => f.locale === this.$store.getters.getLanguage);
    },
    currentLocaleString() {
      return this.currentLocale?.locale ?? "?";
    },
    currentImage() {
      return this.currentLocale?.source ?? "";
    },
  },
  methods: {
    selectLocale(value) {
      if (locales.some((l) => l.locale === value)) {
        this.$store.dispatch("setLanguage", value);
      }
    },
    getClass(locale) {
      return locale.locale == this.currentLocale?.locale
        ? "v-list-item--active"
        : "";
    },
  },
};
</script>
