/* eslint-disable prettier/prettier */
module.exports = [
    {
        title: "English",
        locale: "en",
        source: "https://flagcdn.com/64x48/us.png",
        hasItemTranslation: true,
    },
    {
        title: "український",
        locale: "ua",
        source: "https://flagcdn.com/64x48/ua.png",
        hasItemTranslation: true,
    },
    {
        title: "Deutsch",
        locale: "de",
        source: "https://flagcdn.com/64x48/de.png",
        hasItemTranslation: true,
    },
    {
        title: "Русский",
        locale: "ru",
        source: "https://flagcdn.com/64x48/ru.png",
    },
];
