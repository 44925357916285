<template>
  <v-expansion-panels
    class="ma-2 elevation-16"
    id="main-panel"
    :style="`max-width: ${
      panel === 0 ? 'min(calc(100vw - 60px), 420px)' : '250px'
    }`"
    v-model="panel"
  >
    <v-expansion-panel expand v-model="panel">
      <v-expansion-panel-header>
        <h2>{{ $t("categories.categories") }}</h2>
      </v-expansion-panel-header>
      <v-expansion-panel-content id="main-panel-content">
        <v-list>
          <v-list-item
            v-for="(category, index) in hydratedCategories"
            :key="index"
          >
            <template v-slot:default>
              <v-list-item-action>
                <v-checkbox
                  :input-value="category.active"
                  @click="clickCategory(category)"
                ></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <v-icon> {{ category.icon }} </v-icon>
                  {{ $t(`categories.${category.name}`) }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "MapLayerSelector",
  watch: {
    activePositions: {
      handler() {
        this.$emit("updateMarkers", this.activePositions);
      },
      immediate: true,
    },
  },
  computed: {
    hydratedCategories() {
      let activeCategories = this.$store.getters.getActiveCategories;
      return this.categories.map((category) => {
        return {
          ...category,
          active: activeCategories.includes(category.name),
        };
      });
    },
    activeCategories() {
      return this.hydratedCategories.filter((category) => category.active);
    },
    activePositions() {
      let activePositions = [];
      this.activeCategories.forEach((category) => {
        this.categoryData[category.name].forEach((position) => {
          activePositions.push({
            ...position,
            category: category.name,
            icon: category.icon,
          });
        });
      });
      return activePositions;
    },
  },
  methods: {
    clickCategory(category) {
      this.$store.commit("toggleCategory", category.name);
    },
  },
  data() {
    return {
      selectedCategory: 0,
      panel: 0,
      categories: [
        {
          name: "shelter",
          icon: "mdi-home",
        },
        {
          name: "supermarket",
          icon: "mdi-cart",
        },
        {
          name: "bakery",
          icon: "mdi-baguette",
        },
        {
          name: "restaurant",
          icon: "mdi-silverware",
        },
        {
          name: "bank",
          icon: "mdi-cash-multiple",
        },
        {
          name: "playground",
          icon: "mdi-seesaw",
        },
        {
          name: "church",
          icon: "mdi-church",
        },
        {
          name: "school",
          icon: "mdi-school",
        },
        {
          name: "pharmacy",
          icon: "mdi-hospital",
        },
        {
          name: "secondHandStore",
          icon: "mdi-shopping",
        },
        {
          name: "trainStation",
          icon: "mdi-train",
        },
        {
          name: "townHall",
          icon: "mdi-town-hall",
        },
        {
          name: "police",
          icon: "mdi-police-badge",
        },
      ],
      categoryData: {
        pharmacy: [
          {
            name: "Stadtapotheke",
            latLng: [49.66677779456848, 10.466633505552794],
          },
        ],
        shelter: [
          {
            name: "Unterkunft",
            latLng: [49.664658515796596, 10.45839875720042],
          },
        ],
        supermarket: [
          {
            name: "Aldi",
            latLng: [49.66377725598397, 10.466878134174474],
            image: "aldi",
          },
          {
            name: "Edeka",
            latLng: [49.663882438550246, 10.46553806615081],
            image: "edeka",
          },
          {
            name: "Netto",
            latLng: [49.65992101272077, 10.460908612967739],
            image: "netto",
          },
        ],
        bakery: [
          {
            name: "Bäckerei Fuchs",
            latLng: [49.66378540226645, 10.465732637959718],
          },
          {
            name: "Goldjunge",
            latLng: [49.65982075171674, 10.46099566582355],
          },
        ],
        bank: [
          {
            name: "Sparkasse",
            latLng: [49.6653597336941, 10.463466970282271],
          },
          {
            name: "VR Bank",
            latLng: [49.665836032815655, 10.465727727052776],
          },
        ],
        restaurant: [
          {
            name: "Kebap",
            latLng: [49.66639580108031, 10.465879455695093],
          },
          {
            name: "Kebap",
            latLng: [49.66589986636417, 10.464559416506944],
          },
          {
            name: "Zorbas",
            latLng: [49.667250261953974, 10.462488404100728],
          },
          {
            name: "Weißes Ross - Pizza",
            latLng: [49.66533223135012, 10.46242246230915],
          },
          {
            name: "Metzgerei Brügel",
            latLng: [49.66558527685461, 10.464293409169066],
          },
        ],
        trainStation: [
          {
            name: "Bahnhof Markt Bibart",
            latLng: [49.64631277534782, 10.425364267619694],
          },
        ],
        police: [
          {
            name: "Polizei",
            latLng: [49.66654494871098, 10.461496917089875],
          },
        ],
        townHall: [
          {
            name: "Rathaus",
            latLng: [49.66546776070788, 10.46303454365167],
          },
        ],
        playground: [
          {
            name: "Spielplatz",
            latLng: [49.65384, 10.46584],
          },
          {
            name: "Stadtsee",
            latLng: [49.66652, 10.46511],
          },
          {
            name: "Spielplatz",
            latLng: [49.66381, 10.47055],
          },
          {
            name: "Spielplatz",
            latLng: [49.66111, 10.47039],
          },
          {
            name: "Klosterspielplatz",
            latLng: [49.670111390538985, 10.483924224042607],
          },
          {
            name: "Spielplatz Grappershofen",
            latLng: [49.67427935584172, 10.44475383877239],
          },
          {
            name: "Spielplatz Schnodsenbach",
            latLng: [49.681969196743914, 10.442230249570388],
          },
        ],
        church: [
          {
            name: "Evangelische Kirche",
            latLng: [49.66677779456848, 10.466633505552794],
          },
          {
            name: "Katholische Kirche",
            latLng: [49.665888931119866, 10.462115548377607],
          },
          {
            name: "Kloster",
            latLng: [49.67235433843714, 10.480654922274363],
          },
        ],
        secondHandStore: [
          {
            name: "Komm Rein",
            latLng: [49.66547764140238, 10.463789153877538],
          },
        ],
        school: [
          {
            name: "Realschule",
            latLng: [49.67226953080817, 10.47484290583956],
          },
          {
            name: "Grund- und Mittelschule",
            latLng: [49.672374608145304, 10.462196983245148],
          },
          {
            name: "Gymnasium",
            latLng: [49.66911935544675, 10.466206086762595],
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
#main-panel {
  max-width: 400px;
  max-height: 100%;
  overflow-y: auto;
}
#main-panel-content ::v-deep .v-expansion-panel-content__wrap {
  padding: 5px !important;
}
</style>
